import React, { useState } from 'react'
import { useClasses, clearForm } from '../lib/ReactUtils'
import Loader from './Loader'
import PostBuy from '../lib/PostBuy'
import { useToast } from '../sections/Toast'
import '../styles/Forms'


export default function BuyForm({className = ''}) {
  const root = useClasses()
  const toast = useToast()
  const [errors, setErrors] = useState({name:[], email:[], phone:[], company:[], licenses:[], role:[]})
  const [submitting, setSubmitting] = useState(false)
  const [result, setResult] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const _errors = {name:[], email:[], phone:[], company:[], licenses:[], role:[]}
    const target = e.currentTarget
    const name = target[0].value.trim().replace(/\s+/g,' ')
    const email = target[1].value.replace(/\s/g,'')
    const phone = target[2].value.trim().replace(/\s+/g,' ')
    const role = target[3].value
    const company = target[4].value.trim().replace(/\s+/g,' ')
    const licenses = target[5].value.replace(/\s/g,'')
    if (name.length == 0) _errors.name.push('Please enter your fullname')
    else if (name.length < 5) _errors.name.push('Name too short')
    else if (!name.includes(' ')) _errors.name.push('Please include first and last name')
    if (email.length == 0) _errors.email.push('Please enter your email')
    else if (email.length < 5) _errors.email.push('Email too short')
    else if (!email.includes('@')) _errors.email.push('Invalid email')
    if (phone.length < 5) _errors.phone.push('Please enter your phone in case we need to reach you')
    else if (!/^[0-9\s\+\-\s]/.test(phone)) _errors.email.push('Invalid phone number')
    if (company.length < 3) _errors.company.push('Please enter company name')
    if (licenses.length == 0) _errors.licenses.push('Please enter number of licenses')
    else if (isNaN(Number(licenses))) _errors.licenses.push('Please enter a valid number of licenses')
    setErrors(_errors)
    setResult(null)
    if (
      _errors.name.length || 
      _errors.email.length || 
      _errors.phone.length || 
      _errors.company.length ||
      _errors.licenses.length ||
      _errors.role.length
    ) return
    setSubmitting(true)
    const array = name.split(' ')
    const first = array.shift()
    const last = array.join(' ')
    const result = await PostBuy({first, last, email, phone, company, role, licenses})
    setSubmitting(false)
    if (result) {
      toast.show('Success! We will be in touch in one business day at the most', 'success')
      clearForm(target)
    }
    else toast.show('Failed! Please email us at info@oneoffice.ca', 'error')
    setResult(result)
  }

  return (
    <form ref={root} className={'bg-white ' + className} onSubmit={handleSubmit}>
      <div>
        <input type='text' placeholder=' ' name='name' disabled={submitting}/>
        <label htmlFor='name'>Fullname</label>
        { errors.name.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='email' placeholder=' ' name='email' disabled={submitting}/>
        <label htmlFor='email'>Email</label>
        { errors.email.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='text' placeholder=' ' name='phone' disabled={submitting}/>
        <label htmlFor='phone'>Phone</label>
        { errors.phone.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <select placeholder=' ' name='role' disabled={submitting}>
          <option value="executive">Executive</option>
          <option value="technology">Technology</option>
          <option value="accounting">Accounting</option>
          <option value="sales">Sales</option>
          <option value="purchasing">Purchasing</option>
        </select>
        <label htmlFor='role'>Role</label>
        { errors.role.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='text' placeholder=' ' name='company' disabled={submitting}/>
        <label htmlFor='company'>Company</label>
        { errors.company.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='text' placeholder=' ' name='licenses' disabled={submitting}/>
        <label htmlFor='licenses'>Licenses</label>
        { errors.licenses.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      { result === true ? <div className='text-green-500 font-bold text-large mt-2 leading-snug'>Success! We will be in touch in one business day at the most</div> : null }
      { result === false ? <div className='text-red-500 font-bold text-large mt-2 leading-snug'>Failed! Please email us at info@oneoffice.ca</div> : null }
      <button type='submit' className='button font-bold mt-2'>
        {submitting ? <Loader className='inline mr-3'/> : null}
        Submit
      </button>
    </form>
  )
}
